import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductCard from "../components/ProductCard";
import SortingRow from "../components/SortingRow";
import { getCategoryProducts } from "../services/categories";

const Categories = () => {
	const [productsList, setProductsList] = useState([]);
	const [categoryName, setCategoryName] = useState("");
	const { id } = useParams();

	const handleGetProductList = async () => {
		try {
			const response = await getCategoryProducts(id);
			console.log("Response from getting category product details: ", response);
			setProductsList(response.data.data);
			setCategoryName(response.data.category_name);
		} catch (error) {
			console.log("Error from getting category products deails: ", error);
		}
	};

	useEffect(() => {
		handleGetProductList();
	}, []);

	return (
		<div>
			<div className="xl:px-60 md:px-30 sm:px-10 px-2">
				<div className="my-20">
					<div className="flex items-center justify-center gap-3">
						<div>Home</div>
						<div>{">"}</div>
						<div>Shop</div>
					</div>
					<div className="flex items-center justify-center text-2xl font-bold">
						{categoryName}
					</div>
				</div>
				<SortingRow />
				<div className="grid grid-cols-12 gap-4">
					{productsList.map((product, index) => (
						<ProductCard key={index} productDetails={product} />
					))}
				</div>
			</div>
		</div>
	);
};

export default Categories;
