import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddCartIcon from "../assets/images/AddCardIcon.svg";
import PackComponent from "./PackComponent";
import { MainContext } from "../contextApi/MainContext";

const ProductCard = ({ productDetails = {} }) => {
	const {
		id,
		name,
		thumbnail_image,
		has_discount,
		stroked_price,
		discounted_price,
		bundles,
	} = productDetails;

	const { secondaryBaseColor, baseColor } = useContext(MainContext);
	const [selectedPack, setSelectedPack] = useState(bundles?.[0] || {});
	const navigate = useNavigate();

	return (
		<div className="xl:col-span-3 md:col-span-4 col-span-6 hover:cursor-pointer p-1 md:p-4">
			<div className="h-full w-full bg-white shadow-md rounded-lg overflow-hidden">
				<div onClick={() => navigate(`/product/${id}`)}>
					<img
						src={thumbnail_image}
						className="w-full h-40 lg:h-60 object-fill py-2 px-4"
						alt="Product"
					/>
				</div>
				{bundles?.length ? (
					<div className="flex flex-row flex-wrap lg:flex-nowrap items-center justify-center gap-1 md:gap-2 px-3 w-full mt-4">
						{bundles.map((bundle, index) => (
							<button
								key={index}
								onClick={() => setSelectedPack(bundle)}
								className="w-full"
							>
								<PackComponent
									text={bundle.name}
									selected={selectedPack?.name === bundle.name}
								/>
							</button>
						))}
					</div>
				) : null}
				{/* <div className="flex flex-col items-between grow"> */}
				<div className="text-black mt-4 text-base px-3 overflow-hidden whitespace-nowrap text-ellipsis">{name}</div>
				<div className="flex items-end gap-2 my-2 text-sm px-3">
					{selectedPack?.quantity === 1 ? (
						has_discount ? (
							<>
								<div
									className="font-bold text-2xl"
									style={{ color: baseColor }}
								>
									{discounted_price}
								</div>
								<div className="line-through text-black font-bold">
									{stroked_price}
								</div>
							</>
						) : (
							<div className="font-bold text-2xl" style={{ color: baseColor }}>
								{stroked_price}
							</div>
						)
					) : (
						<>
							<div className="font-bold text-2xl" style={{ color: baseColor }}>
								{selectedPack?.final_price}
							</div>
							<div className="line-through text-black font-bold">
								{selectedPack?.stroked_price}
							</div>
						</>
					)}
				</div>
				<div className="px-3">
					<button
						className="text-white w-full md:px-5 text-sm md:text-base py-2 flex items-center justify-center gap-1 md:gap-2 uppercase mb-2 rounded font-bold"
						style={{ backgroundColor: secondaryBaseColor }}
					>
						<img src={AddCartIcon} alt="Add to cart" />
						Add to cart
					</button>
				</div>
				{/* </div> */}
			</div>
		</div>
	);
};

export default ProductCard;
