import React from "react";

const CheckoutDeliveryTable = ({ tableData }) => {
	return (
		<table className="w-full">
			<tbody className="text-[#9C9C9C]">
				{tableData.map((rowData, index) => (
					<tr className="border-b" key={index}>
						<td className="py-2">{rowData.field}</td>
						<td>{rowData.value}</td>
						<td>Change</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default CheckoutDeliveryTable;
