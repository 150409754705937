import { api } from "./axiosInstance"

export const getBanner = () => {
  return api.get('/sliders');
}

export const getBrands = () => {
  return api.get('/brands');
}

export const getBusinessSettingsList = () => {
  return api.get('/business-settings');
}