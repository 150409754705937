import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick/lib/slider";
import { getFeaturedCategories } from "../services/categories";
import { categorySettings } from "../utils/utils";

const PopularCategories = () => {
	const [categories, setCategories] = useState([]);
	const navigate = useNavigate();

	const getAllCatgories = async () => {
		try {
			const response = await getFeaturedCategories();
			console.log("Response from getting featured categories: ", response);
			setCategories(response.data.data);
		} catch (error) {
			console.log("Error from getting categories", error);
		}
	};

	useEffect(() => {
		getAllCatgories();
	}, []);

	return (
		<div className="px-4 sm:px-6 md:px-10 my-10">
			<span className="text-xl sm:text-2xl md:text-3xl font-normal">
				Popular Categories
			</span>

			{/* Categories Row */}
			<div className="relative mt-8">
				<Slider {...categorySettings(categories.length)}>
					{categories.map((category, index) => (
						<button
							style={{ display: "flex !important justify-center w-full" }}
							onClick={() => navigate(`/categories/${category.slug}`)}
						>
							<div
								key={index}
								className="relative h-40 w-40 rounded-full overflow-hidden flex justify-center items-end text-black text-sm pb-5 bg-cover"
								style={{ backgroundImage: `url(${category.cover_image})` }}
							>
								<div className="absolute inset-0 bg-black bg-opacity-50"></div>
								<div className="relative flex flex-col items-center justify-end z-10 text-white">
									<div>{category.product_counts} products</div>
									<div>{category.name}</div>
								</div>
							</div>
						</button>
					))}
				</Slider>
			</div>
		</div>
	);
};

export default PopularCategories;
