import React, { useState } from "react";
import ProductImage from "../assets/images/productImage.png";

const CheckoutCartCard = () => {
	const [count, setCount] = useState(1);

	return (
		<div className="w-full bg-white p-4 drop-shadow-xl">
			<div className="text-xl">Your Cart</div>
			<div className="flex my-5">
				<img src={ProductImage} alt="Product" className="w-16 h-16" />
				<div className="w-full text-[#595959]">
					<div>
						High Capacity 10000 Puffs Rainbow Candy Disposable Vape- Adjustable
						Airflow & 3MG Freebase{" "}
					</div>
					<div className="flex items-center gap-4">
						<div className="line-through">225.00$</div>
						<div>125.00$</div>
					</div>
					<div className="flex items-center">
						<button
							className="border border-[#D9D9D9] py-2 px-3 rounded-l-full"
							onClick={() => setCount(count - 1)}
						>
							-
						</button>
						<div className="border border-[#D9D9D9] py-2 px-3">{count}</div>
						<button
							className="border border-[#D9D9D9] py-2 px-3 rounded-r-full"
							onClick={() => setCount(count + 1)}
						>
							+
						</button>
					</div>
				</div>
				<div className="text-nowrap">125.00 $</div>
			</div>
			<div className="flex items-center gap-4 py-4 border-t border-[#D9D9D9]">
				<input
					className="p-3 border border-[#D9D9D9] focus:outline-none"
					placeholder="Enter Promo Code"
				/>
				<button className="bg-[#135BFF] w-full p-3 text-white rounded-md">
					Apply
				</button>
			</div>
		</div>
	);
};

export default CheckoutCartCard;
