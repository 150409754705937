import { RouterProvider } from 'react-router-dom';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import './App.css';
import FreeDeliveryBar from './components/FreeDeliveryBar';
import Header from './components/Header';
import { router } from './navigation/routes';
import { MainProvider } from './contextApi/MainContext';

function App() {
  return (
    <div>
      <MainProvider>
        <RouterProvider router={router} >
          <FreeDeliveryBar />
          <Header />
        </RouterProvider>
      </MainProvider>
    </div>
  );
}

export default App;
