import React, { useEffect, useState } from "react";
import SortingRow from "../components/SortingRow";
import { getBrands } from "../services/home";
import BrandCard from "../components/BrandCard";

const AllBrands = () => {
	const [brandsList, setAllBrandList] = useState([]);

	const getAllBrands = async () => {
		try {
			const response = await getBrands();
			console.log("Response from getting all brands: ", response);
			setAllBrandList(response.data.data);
		} catch (error) {
			console.log("Error from getting: ", error);
		}
	};

	useEffect(() => {
		getAllBrands();
	}, []);

	return (
		<div>
			<div className="px-0 md:px-5 2xl:px-60">
				<div className="my-20">
					<div className="flex items-center justify-center gap-3">
						<div>Home</div>
						<div>{">"}</div>
						<div>All Brands</div>
					</div>
					<div className="flex items-center justify-center text-2xl font-bold">
						{/* {categoryName} */}
					</div>
				</div>
				<SortingRow />
				<div className="grid grid-cols-12 gap-4">
					{brandsList.map((brand, index) => (
						<BrandCard key={index} brandDetails={brand} />
					))}
				</div>
			</div>
		</div>
	);
};

export default AllBrands;
