import React, { useState } from "react";
import DeleteIcon from "../assets/images/deleteIcon.svg";
import ProductImage from "../assets/images/product_image.png";

const CartCard = () => {
	const [totalCount, setTotalCount] = useState(1);
	return (
		<div className="flex items-center gap-4">
			<img src={ProductImage} alt="Product" className="h-24 w-24" />
			<div>
				<div className="flex items-center gap-2">
					<span className="font-semibold">High Capacity 10000 Puffs Ra...</span>
					<button>
						<img src={DeleteIcon} alt="delete" />
					</button>
				</div>
				<div className="flex items-center gap-4">
					<div className="flex items-center mt-2">
						<button
							className="py-1 px-2 border border-[#838383]"
							onClick={() => setTotalCount(totalCount - 1)}
						>
							-
						</button>
						<div className="py-1 px-3 border border-[#838383]">
							{totalCount}
						</div>
						<button
							className="py-1 px-2 border border-[#838383]"
							onClick={() => setTotalCount(totalCount + 1)}
						>
							+
						</button>
					</div>
					<span className="font-bold">X 125.00$</span>
				</div>
			</div>
		</div>
	);
};

export default CartCard;
