import React, { useEffect, useState } from "react";
import { getAllProducts } from "../services/products";
import SortingRow from "../components/SortingRow";
import ProductCard from "../components/ProductCard";

const AllProducts = () => {
	const [allProducts, setAllProducts] = useState([]);

	const getAllProductLists = async () => {
		try {
			const response = await getAllProducts();
			console.log("Response from getting all products: ", response);
			setAllProducts(response.data.data);
		} catch (error) {
			console.log("Error from getting all products: ", error);
		}
	};

	useEffect(() => {
		getAllProductLists();
	}, []);

	return (
		<div>
			<div className="px-0 md:px-5 2xl:px-60">
				<div className="my-20">
					<div className="flex items-center justify-center gap-3">
						<div>Home</div>
						<div>{">"}</div>
						<div>Shop</div>
					</div>
					<div className="flex items-center justify-center text-2xl font-bold">
						{/* {categoryName} */}
					</div>
				</div>
				<SortingRow />
				<div className="grid grid-cols-12 gap-4">
					{allProducts.map((product, index) => (
						<ProductCard key={index} productDetails={product} />
					))}
				</div>
			</div>
		</div>
	);
};

export default AllProducts;
