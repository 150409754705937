import { api } from "./axiosInstance"

export const getAllProducts = () => {
  return api.get('/products');
}

export const getTopSellingProducts = () => {
  return api.get('/products/featured');
}

export const getProductBrands = (slug) => {
  return api.get(`/products/brand/${slug}`)
}

export const getProductDetails = (id) => {
  return api.get(`/products/${id}`)
}

export const getTopRelatedProducts = (slug) => {
  return api.get(`/products/frequently-bought/${slug}`)
}