import React, { useContext } from "react";
import MoveToTopIcon from "../assets/images/moveToTop.png";
import RevisitIcon from "../assets/images/revisitIcon.svg";
import StarIcon from "../assets/images/starIcon.png";
import { MainContext } from "../contextApi/MainContext";

const CopyRightRow = () => {
	const { baseColor } = useContext(MainContext);

	return (
		<div className="pb-5" style={{ backgroundColor: baseColor }}>
			<div className="flex items-center justify-center">
				<button className="bg-white p-2 border border-[#00B67A] flex item-center justify-center">
					Review us on
					<img src={StarIcon} />
					Trustpilot
				</button>
			</div>
			<div className="flex items-end justify-between px-10">
				<img src={RevisitIcon} />
				<div className="text-white">
					Copyright © 2024 Vappii.com Powered by eMiracle L.L.C-FZ
				</div>
				<img src={MoveToTopIcon} />
			</div>
		</div>
	);
};

export default CopyRightRow;
