import React, { useEffect, useState } from "react";
import Slider from "react-slick/lib/slider";
import PopularCategories from "../components/PopularCategories";
import TopSellingProducts from "../components/TopSellingProducts";
import { getBanner, getBrands } from "../services/home";

const Home = () => {
	const [brandsList, setBrandsList] = useState([]);
	const [bannerImages, setBannerImages] = useState([]);

	const getBannerImage = async () => {
		try {
			const response = await getBanner();
			console.log("Response from getting banner image: ", response);
			setBannerImages(response.data.data);
		} catch (error) {
			console.log("Error from either backend or frontend: ", error);
		}
	};

	const getBrandsList = async () => {
		try {
			const response = await getBrands();
			console.log("Response from getting brands: ", response);
			setBrandsList(response.data.data);
		} catch (error) {
			console.log(
				"Error from either backend or frontend for getting brands: ",
				error
			);
		}
	};

	useEffect(() => {
		getBannerImage();
		getBrandsList();
	}, []);

	const settings = {
		infinite: true,
		slidesToShow: 1,
		// slidesToScroll: 1,
		autoplay: true,
		speed: 2000,
		autoplaySpeed: 4000,
		cssEase: "linear",
		dots: false,
		arrows: false,
	};

	return (
		<div>
			<Slider {...settings}>
				{bannerImages.map((image, index) => (
					<div key={index}>
						<img
							src={image.photo}
							alt={`Banner ${index}`}
							className="object-fill w-full h-[250px] md:h-[400px] lg:h-[500px] xl:h-[600px]"
						/>
					</div>
				))}
			</Slider>
			<div className="px-0 md:px-5 2xl:px-60">
				<PopularCategories />
				{brandsList.map((brand, index) => (
					<TopSellingProducts
						title={brand.name}
						slug={brand.slug}
						key={index}
					/>
				))}
			</div>
		</div>
	);
};

export default Home;
