import React from "react";
import CheckoutDeliveryTable from "../CheckoutDeliveryTable";
import CheckoutRadioButton from "./CheckoutRadioButton";

const Shipping = () => {
	const tableData = [
		{ field: "Contact", value: "shabnumnaz35@gmail.com" },
		{ field: "Address", value: "shabnum naz, sadhsuifh, sdadd//sade" },
	];

	return (
		<div>
			<div className="w-full bg-white p-6 drop-shadow-xl">
				<CheckoutDeliveryTable tableData={tableData} />
			</div>
			<div className="mt-4">
				<CheckoutRadioButton
					label={"GLS Pickup Method"}
					hasDescription={true}
					description={"Shipping to closest pick-up point"}
					checked={true}
				/>
			</div>
			<div className="mt-4">
				<CheckoutRadioButton
					label={"GLS Private Method"}
					checked={false}
					price={5.0}
				/>
			</div>
			<div className="mt-4">
				<CheckoutRadioButton
					label={"GLS Business Method"}
					checked={false}
					price={5.0}
				/>
			</div>
		</div>
	);
};

export default Shipping;
