import React, { useContext, useState } from "react";
import OrderWhatsappImage from "../assets/images/orderWhatsapp.png";
import StarsGroupIcon from "../assets/images/starsGroup.png";
import PackComponent from "./PackComponent";
import { MainContext } from "../contextApi/MainContext";

const ProductDetails = ({ productDetails }) => {
	const { baseColor, secondaryBaseColor } = useContext(MainContext);
	const [selectedPack, setSelectedPack] = useState(
		productDetails?.bundles?.[0]?.id || -1
	);

	return (
		<div className="space-y-6 lg:flex lg:space-x-10 lg:space-y-0">
			<div className="lg:w-2/3">
				<div className="text-lg sm:text-xl lg:text-2xl font-semibold">
					{productDetails?.name}
				</div>
				<div className="my-4">
					<img src={StarsGroupIcon} alt="Stars" className="w-auto h-auto" />
				</div>
				<div>44 reviews</div>

				<div className="flex items-center gap-2">
					<div className="font-semibold">SKU:</div>
					<div>{productDetails?.sku}</div>
				</div>
				<div className="flex items-center gap-2">
					<div className="font-semibold">Categories:</div>
					<div>10,000</div>
				</div>
				<div className="flex items-center gap-2">
					<div className="font-semibold">Tag:</div>
					{productDetails?.tags.split(",").map((tag, index) => (
						<div key={index}>
							{tag}
							{index + 1 < productDetails?.tags.split(",").length ? "," : ""}
						</div>
					))}
				</div>

				{productDetails?.bundles?.length ? (
					<div className="flex items-center gap-1 md:gap-2 mt-4">
						{productDetails?.bundles.map((bundle, index) => (
							<button
								onClick={() => setSelectedPack(bundle.id)}
								className=""
								key={index}
							>
								<PackComponent
									text={bundle.name}
									selected={selectedPack === bundle.id}
								/>
							</button>
						))}
					</div>
				) : null}

				{/* Add to Cart Section */}
				<div className="flex items-center gap-4 mt-6">
					<button>
						<img
							src={OrderWhatsappImage}
							alt="Order via WhatsApp"
							className="h-10"
						/>
					</button>
					<div
						className="flex items-center border rounded-md"
						style={{ borderColor: baseColor }}
					>
						<input
							type="number"
							className="w-16 px-2 py-1 border-0 focus:outline-none"
							defaultValue="1"
						/>
						<button
							className="text-white py-3 px-2 uppercase text-sm font-semibold text-nowrap"
							style={{ backgroundColor: secondaryBaseColor }}
						>
							Add to Cart
						</button>
					</div>
				</div>
			</div>

			{/* Price Section */}
			<div>
				<div
					className="text-end text-sm sm:text-base"
					style={{ color: baseColor }}
				>
					{productDetails?.current_stock} in stock
				</div>
				{productDetails?.has_discount ? (
					<>
						<div
							className="flex no-wrap line-through text-white py-1 px-16 font-bold mt-2 w-full rounded text-lg"
							style={{ backgroundColor: secondaryBaseColor }}
						>
							{productDetails?.unit_price}
						</div>
						<div
							className="flex no-wrap text-white py-1 px-16 font-bold mt-1 w-full rounded text-lg"
							style={{ backgroundColor: baseColor }}
						>
							{productDetails?.discounted_price}
						</div>
					</>
				) : (
					<div
						className="flex no-wrap text-white py-1 px-6 font-bold mt-1 w-full"
						style={{ backgroundColor: baseColor }}
					>
						{productDetails?.unit_price}
					</div>
				)}
			</div>
		</div>
	);
};

export default ProductDetails;
