import React, { useEffect, useState } from "react";
import ProductCard from "../components/ProductCard";
import ProductDescription from "../components/ProductDescription";
import ProductDetailCard from "../components/ProductDetailCard";
import ProductDetails from "../components/ProductDetails";
import ProductReviews from "../components/ProductReviews";
import { Link, useParams } from "react-router-dom";
import { getProductDetails, getTopRelatedProducts } from "../services/products";

const ProductDetail = () => {
	const [selectedTab, setSelectedTab] = useState("description");
	const [productDetails, setProductDetails] = useState();
	const [relatedProducts, setRelatedProducts] = useState([]);

	const { id } = useParams();

	const handleGetRelatedProducts = async (slug) => {
		try {
			const response = await getTopRelatedProducts(slug);
			console.log("Response from getting related product details: ", response);
			setRelatedProducts(response.data.data);
		} catch (error) {
			console.log("Error from getting related prodcut deails: ", error);
		}
	};

	const handleGetProductDetails = async () => {
		try {
			const response = await getProductDetails(id);
			console.log("Response from getting product details: ", response);
			setProductDetails(response.data.data);
			handleGetRelatedProducts(response?.data?.data?.slug);
		} catch (error) {
			console.log("Error from getting prodcut deails: ", error);
		}
	};

	useEffect(() => {
		handleGetProductDetails();
	}, []);

	return (
		<div className="px-5 2xl:px-60">
			<div className="my-20">
				{/* Breadcrumb */}
				<div className="flex items-center gap-3 text-sm text-[#838383]">
					<Link to="/">Home</Link>
					<div>{">"}</div>
					<div>{productDetails?.category_name}</div>
				</div>

				{/* Product Title */}
				<div className="text-2xl sm:text-3xl lg:text-4xl font-bold mt-4">
					{productDetails?.name}
				</div>
			</div>

			{/* Product Grid */}
			<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-12 gap-10">
				{/* Left Column: Product Detail Card */}
				<div className="col-span-1 sm:col-span-2 lg:col-span-4">
					<ProductDetailCard productDetails={productDetails} />
				</div>

				{/* Right Column: Product Details */}
				<div className="col-span-1 sm:col-span-2 lg:col-span-8">
					<ProductDetails productDetails={productDetails} />
				</div>
			</div>

			{/* Tab Navigation */}
			<div className="flex items-center gap-10 mt-10 text-xl sm:text-2xl">
				<button
					onClick={() => setSelectedTab("description")}
					className={`${
						selectedTab === "description" ? "text-black" : "text-[#838383]"
					}`}
				>
					Description
				</button>
				<button
					onClick={() => setSelectedTab("review")}
					className={`${
						selectedTab === "review" ? "text-black" : "text-[#838383]"
					}`}
				>
					Review
				</button>
			</div>

			{/* Tab Content */}
			<div className="border-y border-[#BBBBBB] py-8">
				{selectedTab === "description" ? (
					<ProductDescription description={productDetails?.description} />
				) : (
					<ProductReviews />
				)}
			</div>

			{/* Related Products */}
			{relatedProducts?.length ? (
				<div className="mt-10">
					<div className="text-2xl sm:text-3xl lg:text-4xl font-bold">
						Related Products
					</div>
					<div className="grid grid-cols-12 gap-4 mt-4">
						{relatedProducts.map((product, index) => (
							<ProductCard key={index} productDetails={product} />
						))}
					</div>
				</div>
			) : null}
		</div>
	);
};

export default ProductDetail;
