import React from "react";

const SortingRow = () => {
	return (
		<div className="flex items-center justify-end my-1">
			<div className="flex items-center justify-end gap-3">
				<div className="text-[#838383]">Show</div>
				<select name="number" className="p-1 bg-white border border-[#59008E]">
					<option value="volvo">16</option>
					<option value="saab">10</option>
					<option value="mercedes">8</option>
					<option value="audi">4</option>
				</select>
			</div>
		</div>
	);
};

export default SortingRow;
