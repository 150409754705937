import React, { useState } from "react";

const AuthDropdown = () => {
	const [selectedTab, setSelectedTab] = useState("login");

	return (
		<div className="absolute right-0 bg-white px-4 pt-10 pb-6 w-auto text-[#1F1F1F]">
			<div className="flex items-center justify-between w-80 text-sm">
				<button
					onClick={() => setSelectedTab("login")}
					className={`text-center w-full pb-2 ${
						selectedTab === "login"
							? "border-b-2 border-[#9C9C9C] font-medium"
							: "border-b border-[#9C9C9C] text-[#9C9C9C]"
					}`}
				>
					LOGIN
				</button>
				<button
					onClick={() => setSelectedTab("register")}
					className={`text-center w-full pb-2 ${
						selectedTab === "register"
							? "border-b-2 border-[#9C9C9C]"
							: "border-b border-[#9C9C9C] text-[#9C9C9C]"
					}`}
				>
					REGISTER
				</button>
			</div>
			{selectedTab === "login" ? (
				<div>
					<div className="mt-3 font-medium">
						<div>Username or email *</div>
						<input className="p-1 border border-[#59008E] w-full focus:border-2 focus:outline-none" />
					</div>
					<div className="mt-3 font-medium">
						<div>Password *</div>
						<input
							className="p-1 border border-[#59008E] w-full focus:border-2 focus:outline-none"
							type="password"
						/>
					</div>
					<div className="mt-3 flex items-center justify-between">
						<div className="flex items-center gap-2">
							<input type="checkbox" className="border border-[#C5C5C5]" />
							Remember me
						</div>
						<div>Lost password?</div>
					</div>
					<button className="w-full mt-3 bg-[#59008E] text-white p-2 rounded-full">
						Log in
					</button>
				</div>
			) : (
				<div>
					<div className="mt-3 font-medium">
						<div>Email address *</div>
						<input className="p-1 border border-[#59008E] w-full focus:border-2 focus:outline-none" />
					</div>
					<div className="mt-3">
						A password will be sent to your email address.
					</div>
					<div className="flex items-center justify-between">
						Your personal data will be used to support your experience
						throughout this website, to manage access to your account, and for
						other purposes described in our privacy policy.
					</div>
					<button className="w-full mt-3 bg-[#59008E] text-white p-2 rounded-full">
						Register
					</button>
				</div>
			)}
		</div>
	);
};

export default AuthDropdown;
