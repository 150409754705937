import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick/lib/slider";
import { MainContext } from "../contextApi/MainContext";
import { getProductBrands } from "../services/products";
import { settings } from "../utils/utils";
import ProductCard from "./ProductCard";

const TopSellingProducts = ({ title, slug }) => {
	const [products, setProducts] = useState([]);

	const { baseColor } = useContext(MainContext);

	const getTopSellingProductsList = async () => {
		try {
			const response = await getProductBrands(slug);
			console.log("Response from getting top selling products: ", response);
			setProducts(response.data.data);
		} catch (error) {
			console.log("Error from getting top selling products: ", error);
		}
	};

	useEffect(() => {
		getTopSellingProductsList();
	}, []);

	return (
		<div className="p-4 sm:p-6 md:p-10">
			{/* Title Button */}
			<div className="flex justify-center">
				<button
					className="uppercase text-white text-sm sm:text-base py-2 px-3"
					style={{ backgroundColor: baseColor }}
				>
					{title}
				</button>
			</div>

			{/* Section Title */}
			<div className="flex items-center justify-center text-2xl sm:text-3xl font-bold my-10">
				<div
					className="flex-grow border-y mx-2 py-0.5"
					style={{ borderColor: baseColor }}
				/>
				Top Selling Product
				<div
					className="flex-grow border-y mx-2 py-0.5"
					style={{ borderColor: baseColor }}
				/>
			</div>

			<div className="slider-container">
				<Slider {...settings(products.length)}>
					{products.map((product, index) => (
						<ProductCard key={index} productDetails={product} />
					))}
				</Slider>
			</div>
		</div>
	);
};

export default TopSellingProducts;
