import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import CheckoutCartCard from "../components/CheckoutCartCard";
import Information from "../components/checkoutForms/Information";
import Payment from "../components/checkoutForms/Payment";
import Shipping from "../components/checkoutForms/Shipping";

const validationSchema = Yup.object().shape({
	email: Yup.string().email().required("This value is required"),
	telephone: Yup.string().required("This value is required"),
	firstName: Yup.string().required("This value is required"),
	lastName: Yup.string().required("This value is required"),
	streetAddress: Yup.string().required("This value is required"),
	postCode: Yup.string().required("This value is required"),
	townOrCity: Yup.string().required("This value is required"),
});

const Checkout = () => {
	const [formStep, setFormStep] = useState(0);

	const formTitles = ["Information", "Shipping", "Payment"];

	const pageDisplay = (formik) => {
		switch (formStep) {
			case 0:
				return <Information formik={formik} />;
			case 1:
				return <Shipping />;
			case 2:
				return <Payment />;
		}
	};

	const handleNext = (formik) => {
		const { values, isValid, validateField, setTouched, errors } = formik;
		if (
			(formStep === 0 && !values.firstName,
			!values.email,
			!values.telephone,
			!values.lastName,
			!values.streetAddress,
			!values.postCode,
			!values.townOrCity)
		) {
			validateField(
				"email",
				"telephone",
				"firstName",
				"lastName",
				"streetAddress",
				"postCode",
				"townOrCity"
			);
			setTouched({
				email: true,
				telephone: true,
				firstName: true,
				lastName: true,
				streetAddress: true,
				postCode: true,
				townOrCity: true,
			});
		} else if (
			formStep === 0 &&
			!errors.email &&
			!errors.telephone &&
			!errors.firstName &&
			!errors.lastName &&
			!errors.streetAddress &&
			!errors.postCode &&
			!errors.townOrCity
		) {
			setFormStep(1);
		}
		if (formStep === 1 && !values.planOption) {
			setTouched({
				planOption: true,
			});
		} else if (formStep === 1 && isValid) {
			setFormStep(2);
		}
		if (formStep === 2) {
			setFormStep(3);
		}
	};

	return (
		<div className="bg-[#EEEAE7]">
			<div className="px-60">
				{/* GRIDS */}
				<div className="grid grid-cols-12 gap-4">
					<div className="col-span-7">
						<div className="flex items-center">
							{formTitles.map((title, index) => (
								<button
									onClick={() => setFormStep(index)}
									className={`w-full border-b ${
										index === formStep
											? "border-blue-500 text-blue-500"
											: "border-[#BEBEBE] text-[#BEBEBE]"
									} flex items-center justify-center py-3 text-lg`}
								>
									{index + 1} {title}
								</button>
							))}
						</div>
						<Formik
							validateOnBlur={false}
							validateOnChange={false}
							initialValues={{
								email: "",
								telephone: "",
								firstName: "",
								lastName: "",
								streetAddress: "",
								postCode: "",
								townOrCity: "",
							}}
							validationSchema={validationSchema}
						>
							{(formik) => {
								return (
									<Form className="form">
										<div className="font-normal text-2xl my-2">
											Delivery Address
										</div>
										{pageDisplay(formik)}
										<div className="w-full flex items-center justify-between my-6">
											<button className="text-[#0576AC]">
												{"<<"} Return to cart
											</button>
											<button
												className="bg-[#135BFF] text-white rounded-md p-6"
												onClick={() => handleNext(formik)}
											>
												Continue to Shipping
											</button>
										</div>
									</Form>
								);
							}}
						</Formik>
					</div>
					<div className="col-span-5 py-3">
						<CheckoutCartCard />
						<div className="my-6 flex items-center justify-between text-[#616161] text-sm">
							<span>SUBTOTAL</span>
							<span>125.00$</span>
						</div>
						<div className="mt-6 flex items-center justify-between text-[#616161] text-sm">
							<span>SHIPPING</span>
							<span>Free!</span>
						</div>
						<div className="my-2 py-2 flex items-center justify-between border-t border-black">
							<span>TOTAL</span>
							<span>125.00$</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Checkout;
