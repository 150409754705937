import React, { useContext, useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import CartIcon from "../assets/images/cartIconBlack.svg";
import ChevronDownIcon from "../assets/images/chevronDownIcon.svg";
import CloseIcon from "../assets/images/closeIcon.png";
import HamburgerIcon from "../assets/images/hamburgerMenu.png";
import LogoImage from "../assets/images/logo.png";
import SearchIcon from "../assets/images/searchIcon.svg";
import UserIcon from "../assets/images/userIcon.svg";
import AuthDropdown from "./AuthDropdown";
import CartCard from "./CartCard";
import CartDrawer from "./CartDrawer";
import { MainContext } from "../contextApi/MainContext";
import { getHeaderLogo } from "../services/header";

const Header = () => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
	const [isFuryOpen, setIsFuryOpen] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [headerLogo, setHeaderLogo] = useState("");
	const navigate = useNavigate();

	const { businessSettings } = useContext(MainContext);

	const headerLabels = businessSettings.filter(
		(bs) => bs.type === "header_menu_labels"
	);

	const headerLabelLinks = businessSettings.filter(
		(bs) => bs.type === "header_menu_links"
	);

	const handleMouseEnter = () => {
		setIsOpen(true);
	};

	const handleMouseLeave = () => {
		setIsOpen(false);
	};

	const handleMouseEnterFury = () => {
		setIsFuryOpen(true);
	};

	const handleMouseLeaveFury = () => {
		setIsFuryOpen(false);
	};

	const getHeaderLogoIcon = async () => {
		try {
			const response = await getHeaderLogo();
			console.log("Response from getting header logo: ", response);
			setHeaderLogo(response.data.header_logo);
		} catch (error) {
			console.log("Error from getting header logo: ", error);
		}
	};

	useEffect(() => {
		getHeaderLogoIcon();
	}, []);

	return (
		<>
			<div className="flex justify-between items-center py-3 px-4 md:px-10 lg:px-20 xl:px-60">
				{/* Logo */}
				<Link to="/">
					<img src={headerLogo} className="h-16 w-auto object-contain" />
				</Link>

				{/* Desktop Navigation Menu */}
				<div className="hidden lg:flex gap-6 text-sm lg:text-base font-medium">
					{/* <div
						className="relative"
						onMouseEnter={handleMouseEnterFury}
						onMouseLeave={handleMouseLeaveFury}
					>
						<button className="flex items-center gap-2">
							FURY
							<img src={ChevronDownIcon} />
						</button>
						{isFuryOpen && (
							<div className="absolute left-0 bg-white px-4 pt-10 pb-2 w-auto text-[#1F1F1F]">
								<div className="my-2 text-nowrap">10,000 Puffs</div>
								<div className="my-2">12,500 Puffs</div>
								<div className="my-2">15,000 Puffs</div>
							</div>
						)}
					</div> */}
					{headerLabels.length &&
						headerLabelLinks.length &&
						JSON.parse(headerLabels[0].value).map((hl, index) => (
							<Link
								key={index}
								to={JSON.parse(headerLabelLinks[0].value)[index]}
							>
								{hl}
							</Link>
						))}
				</div>

				{/* Right-side Icons (Search, User, Cart) */}
				<div className="flex items-center gap-4 lg:gap-6 text-sm lg:text-base">
					<img src={SearchIcon} className="h-4 w-4 lg:h-5 lg:w-5" />
					<div
						className="relative"
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
					>
						<button>
							<img src={UserIcon} className="h-4 w-4 lg:h-5 lg:w-5" />
						</button>
						{isOpen && <AuthDropdown />}
					</div>
					<div className="hidden sm:block">0.00 $</div>
					<button onClick={() => setIsDrawerOpen(true)}>
						<img src={CartIcon} className="h-4 w-4 lg:h-5 lg:w-5" />
					</button>
				</div>

				{/* Mobile Hamburger Menu Button */}
				<div className="lg:hidden flex items-center gap-4">
					<button
						onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
						className="text-lg"
					>
						<img
							src={isMobileMenuOpen ? CloseIcon : HamburgerIcon}
							className="h-6 w-6"
						/>
					</button>
				</div>
			</div>

			{/* Mobile Navigation Menu (visible when `isMobileMenuOpen` is true) */}
			{isMobileMenuOpen && (
				<div className="lg:hidden bg-white shadow-md py-4 px-6">
					<div className="flex flex-col gap-4 text-sm font-medium">
						{headerLabels.length &&
							headerLabelLinks.length &&
							JSON.parse(headerLabels[0].value).map((hl, index) => (
								<Link
									key={index}
									to={JSON.parse(headerLabelLinks[0].value)[index]}
								>
									{hl}
								</Link>
							))}
					</div>
				</div>
			)}

			{/* Cart Drawer */}
			<CartDrawer isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen}>
				<div className="flex flex-col flex-grow">
					<div className="flex justify-center items-center">
						<img src={CartIcon} alt="cart" />
					</div>
					<div className="text-center my-2 text-sm md:text-base">
						SHOPPING CART
					</div>
					<div className="px-10 my-6 flex-grow">
						<CartCard />
					</div>
					<div className="px-10">
						<div className="flex items-center justify-between uppercase font-semibold text-sm md:text-base">
							<span className="text-[#656565]">subtotal</span>
							<span>125.00$</span>
						</div>
						<button
							className="rounded-full w-full bg-[#59008E] text-white py-2 mt-3 font-medium text-sm md:text-base"
							onClick={() => navigate("/checkout")}
						>
							Checkout
						</button>
					</div>
				</div>
			</CartDrawer>
		</>
	);
};

export default Header;
